<template>
  <div class="trash-history">
    <div class="title" @click="isCollapsed = !isCollapsed">Trash ({{ (trash && trash.length) || 0 }})</div>
    <div v-for="(trash, date) of trashHistory" :key="date" v-show="!isCollapsed">
      <Table :title="getTitle(trash)" :rows="trash.rows" :fields="fields" collapsed>
        <template #titleActions>
          <action @click="restore(trash.deleteUUID)">Restore</action>
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

import Action from '@/components/Action'
import Table from '@/components/Table'
import { openConfirmationPopup } from '@/components/ConfirmationPopup'

import api from '@/utils/api'

export default {
  name: 'TrashHistory',
  components: {
    Action,
    Table,
  },
  props: {
    trash: Array,
  },
  data() {
    return {
      isCollapsed: true,
      fields: {
        entity: { title: 'Entity', width: '150px' },
        entityTitle: { title: 'Title' },
      },
    }
  },
  computed: {
    trashHistory() {
      const trashHistory = {}
      this.trash
        .filter(trash => !['ContentSystem', 'ContentSystemBlock'].includes(trash.entity))
        .sort((a, b) => (a.entity > b.entity ? 1 : -1))
        .forEach(trash => {
          const date = moment(trash.createdAt).format('YYYY-MM-DD HH:mm:ss')
          const deletedBy = [trash.firstName, trash.lastName, `(${trash.email})`].filter(value => value).join(' ')
          trashHistory[date] = trashHistory[date] || { rows: [], date, deletedBy, deleteUUID: trash.deleteUUID }
          trashHistory[date].rows.push(trash)
        })
      return trashHistory
    },
  },
  methods: {
    getTitle(trash) {
      return `${trash.date} [${trash.deletedBy}]`
    },
    restore(deleteUUID) {
      openConfirmationPopup({
        text: 'Are you sure you want to restore all trashed content?',
      })
        .then(async () => {
          await api.restoreTrash(deleteUUID)
          this.$emit('updateRequired')
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="sass" scoped>
.trash-history
  width: 100%
  font-size: 14px
  border-radius: 2px

.title
  text-align: center
  font-size: 18px
  padding: 20px
  cursor: pointer
</style>
